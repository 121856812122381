<template>
  <v-col cols="12">
    <span class="subtitle-2">{{ $t('requestForm.personalData.sectionHeader') }}</span>
    <br />
    <i class="caption">{{ $t('requestForm.personalData.instruction') }}</i>
    <v-row>
      <v-col md="4" cols="12">
        <v-radio-group v-model="form.isSwedishNational" :rules="radioRules">
          <v-radio :label="$t('generic.yes')" :value="true"></v-radio>
          <v-radio :label="$t('generic.no')" :value="false"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col v-if="form.isSwedishNational" md="4" cols="6">
        <v-text-field
          v-model="form.socialSecurityNumber"
          v-mask="'########-####'"
          :label="$t('requestForm.personalData.socialSecurityNumber')"
          :rules="socialSecurityNumberRules"
          max="13"
          @change="update"
        ></v-text-field>
      </v-col>
      <template v-else-if="form.isSwedishNational === false">
        <v-col md="4" cols="6">
          <v-menu
            v-model="bdmenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="form.birthDate"
                :label="$t('requestForm.personalData.dateLabel')"
                prepend-icon="mdi-calendar"
                readonly
                :rules="birthDateRules"
                v-on="on"
                @change="update"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="bdPicker"
              v-model="form.birthDate"
              min="1950-01-01"
              max="2003-01-01"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="4" cols="6">
          <v-text-field
            v-model="form.passportNumber"
            filled
            :label="$t('requestForm.personalData.passportNumber')"
            :rules="passportRules"
            @change="update"
          ></v-text-field>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.firstName"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :rules="firstNameRules"
          :label="$t('requestForm.personalData.firstName')"
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.lastName"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :rules="lastNameRules"
          :label="$t('requestForm.personalData.lastName')"
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.email"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :rules="emailRules"
          :label="$t('requestForm.personalData.email')"
          required
          @change="update"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.phoneNumber"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :label="$t('requestForm.personalData.phoneNumber')"
          :rules="phoneRules"
          required
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.company"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :label="$t('requestForm.personalData.companyName')"
          :rules="companyRules"
          required
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.profession"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :label="$t('requestForm.personalData.profession')"
          :rules="professionRules"
          required
          @change="update"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import dayjs from 'dayjs'
import validators from '../validators'

export default {
  name: 'PersonalData',
  props: {
    value: { type: Object, default: () => {} }
  },
  emits: ['input'],
  data () {
    return {
      ...validators,
      dayjs,
      bdmenu: false,
      form: { ...this.value }
    }
  },
  watch: {
    bdmenu (val) {
      val && setTimeout(() => (this.$refs.bdPicker.activePicker = 'YEAR'))
    }
  },
  methods: {
    update () {
      this.$emit('input', this.form)
    }
  }
}
</script>
