<template>
  <div>
    <v-col cols="12">
      <span class="subtitle-2">{{ $t('requestForm.trainingInformation.sectionHeader') }}</span>
      <br />
      <i class="caption">{{ $t('requestForm.trainingInformation.instruction') }}</i>
      <v-row>
        <v-col md="4" cols="6">
          <v-radio-group v-model="form.isTrainingCompleted" :rules="radioRules" @change="update">
            <v-radio :label="$t('generic.yes')" :value="true"></v-radio>
            <v-radio :label="$t('generic.no')" :value="false" @click="trainingDialog = true"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col v-if="form.isTrainingCompleted" md="4" cols="6">
          <v-menu
            v-model="trainingMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="form.trainingDate"
                :label="$t('requestForm.trainingInformation.dateLabel')"
                prepend-icon="mdi-calendar"
                readonly
                :rules="trainingDateRules"
                @change="update"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.trainingDate"
              :max="dayjs().format('YYYY-MM-DD')"
              min="2020-01-01"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog v-model="trainingDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          {{
          $t('requestForm.trainingInformation.trainingDialog.header')
          }}
        </v-card-title>
        <v-container>
          <v-row>
            <v-col sm="12">
              <p v-html="$t('requestForm.trainingInformation.trainingDialog.message')"></p>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-card-actions>
          <v-btn color="primary" class="px-5" rounded @click="trainingDialog = false">
            {{
            $t('actions.proceed')
            }}
          </v-btn>
          <v-spacer />
          <v-btn color="primary" rounded outlined @click="handleLearningClick">
            {{
            $t('actions.goToTraining')
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import validators from '../validators'

export default {
  name: 'TrainingSection',
  props: {
    value: { type: Object, default: () => {} }
  },
  emits: ['input'],
  data () {
    return {
      ...validators,
      dayjs,
      form: { ...this.value },
      trainingMenu: false,
      trainingDateModal: false,
      trainingDialog: false
    }
  },
  methods: {
    handleLearningClick () {
      window.location = `/learning/${this.$i18n.locale}/index.html?returnUrl=${encodeURIComponent(
        process.env.VUE_APP_AD_CALLBACK_URL + '/#/?id=' + this.referenceCode
      )}`
    },
    update () {
      this.$emit('input', this.form)
    }
  }
}
</script>
