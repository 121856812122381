<template>
  <v-col cols="12">
    <v-sheet>
      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="form.reason"
            filled
            required
            :rules="reasonRules"
            :label="$t('requestForm.reasonDescription.reasonLabel')"
            :placeholder="$t('requestForm.reasonDescription.reasonPlaceholder')"
            :hint="$t('requestForm.reasonDescription.reasonPlaceholder')"
            :persistent-hint="true"
            @change="update"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-sheet>
  </v-col>
</template>
<script>
import validators from '../validators'

export default {
  name: 'ReasonDescription',
  props: {
    value: { type: Object, default: () => {} }
  },
  emits: ['input'],
  data () {
    return {
      ...validators,
      form: { ...this.value }
    }
  },
  methods: {
    update () {
      this.$emit('input', this.form)
    }
  }
}
</script>
