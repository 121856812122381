import dayjs from 'dayjs'

export default {
  capitalize: (value) => {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
  capitalizeEachWord: (value) => {
    if (!value) return ''
    value = value.toString()
    return value.replace(/\b[a-z]/g, function (letter) {
      return letter.toUpperCase()
    })
  },
  upperCase: (value) => {
    if (!value) return ''
    value = value.toString()
    return value.toUpperCase()
  },
  reverse: (value) => {
    value.slice().reverse()
  },
  dateTime: (value) => {
    return dayjs(value).format('YYYY-MM-DD HH:mm')
  },
  time: (value) => {
    const formattedTime = dayjs(value).format('HH:mm')
    return formattedTime === '00:59' ? '' : formattedTime
  }

}
