<template>
  <v-app>
    <v-app-bar color="white" fixed>
      <v-btn icon class="hidden-xs-only">
        <v-icon @click="$router.go(-1)">mdi-arrow-left</v-icon>
      </v-btn>

      <div class="d-flex">
        <img alt="Vattenfall Logo" src="@/assets/logo.svg" width="50%" />
      </div>
      <v-spacer />
      <v-toolbar-title>
        <strong>Physical Access</strong> Management
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn depressed plain @click="handleContactButtonClick">{{ $t('appBar.contact') }}</v-btn>
      <LocaleSwitcher></LocaleSwitcher>
    </v-app-bar>
    <progress-bar :loading="isFetching" />
    <div class="my-16"></div>
    <router-view />
    <snack-bar />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { statuses } from './helpers'
export default {
  name: 'App',
  data: () => ({
    drawer: null
  }),
  computed: {
    ...mapGetters(['isFetching'])
  },
  watch: {},
  methods: {
    handleContactButtonClick () {
      window.location.href = `mailto:${this.$t('appBar.contactEmail')}`
    }
  }
}
</script>
