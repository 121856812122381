<template>
  <v-col cols="12">
    <span class="subtitle-2">{{ $t('requestForm.deliveryAddress.sectionHeader') }}</span>
    <br />
    <i class="caption">{{ $t('requestForm.deliveryAddress.instruction') }}</i>

    <v-row>
      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.recipient"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :rules="recipientRules"
          :label="$t('requestForm.deliveryAddress.recipient')"
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col md="8" cols="12">
        <v-text-field
          v-model="form.street"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :rules="streetRules"
          :label="$t('requestForm.deliveryAddress.street')"
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.zipcode"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :rules="zipcodeRules"
          :label="$t('requestForm.deliveryAddress.zipcode')"
          required
          @change="update"
        ></v-text-field>
      </v-col>

      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.city"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :label="$t('requestForm.deliveryAddress.city')"
          :rules="cityRules"
          required
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col md="4" cols="6">
        <v-text-field
          v-model="form.country"
          autocomplete="random-value-to-not-show-previous-data"
          filled
          :label="$t('requestForm.deliveryAddress.country')"
          :rules="countryRules"
          required
          @change="update"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import dayjs from 'dayjs'
import validators from '../validators'

export default {
  name: 'DeliveryAddress',
  props: {
    value: { type: Object, default: () => {} }
  },
  emits: ['input'],
  data () {
    return {
      ...validators,
      dayjs,
      form: { ...this.value }
    }
  },
  watch: {
    bdmenu (val) {
      val && setTimeout(() => (this.$refs.bdPicker.activePicker = 'YEAR'))
    }
  },
  methods: {
    update () {
      this.$emit('input', this.form)
    }
  }
}
</script>
