<template>
  <v-row class="py-10 px-5 justify-center">
    <v-card class="d-flex px-8">
      <v-icon x-large>mdi-information</v-icon>
      <v-card-text
        class="text-h6 text-center"
      >{{ $t('requestForm.accessCards.applicationNotification') }}</v-card-text>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: 'AccessCards'
}
</script>
