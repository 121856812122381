import axios from 'axios'
import Vue from 'vue'

const config = {
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  timeout: 300 * 1000
}
const _axios = axios.create(config)

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    return Promise.reject(error)
  }
)

Vue.use({
  install (vue, opts = {}) {
    vue.prototype.$api = _axios
    Vue.axios = axios
    window.axios = vue.prototype.$api
  }
})

export default Plugin
