<template>
  <div>
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn x-small color="white" elevation="0" v-bind="attrs" v-on="on">
          <img :src="icons[currentLocale]" class="country-icon as-toggle" />
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="locale in locales"
          :key="locale.code"
          @click="handleLocaleClick(locale.code)"
        >
          <v-list-item-icon>
            <img :src="icons[locale.code]" class="country-icon" />
          </v-list-item-icon>
          <v-list-item-title>{{ locale.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import enIcon from 'svg-country-flags/svg/gb.svg'
import seIcon from 'svg-country-flags/svg/se.svg'

export default {
  data () {
    return {
      icons: {
        en: enIcon,
        sv: seIcon
      },
      locales: [
        { code: 'en', name: 'English' },
        { code: 'sv', name: 'Svenska' }
      ]
    }
  },

  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    handleLocaleClick (code) {
      this.$i18n.locale = code
    }
  }
}
</script>

<style scoped>
.country-icon {
  width: 25px;
  height: auto;
  display: inline-block;
  vertical-align: baseline;
}

.locale-name {
  display: inline-block;
  vertical-align: baseline;
}
</style>
