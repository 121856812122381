<template>
  <v-col cols="12">
    <i class="caption">{{ $t('requestForm.assetsSection.powerPlantInformation') }}</i>
    <v-sheet>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectedPlants"
            :items="assets"
            item-text="name"
            item-value="id"
            clearable
            deletable-chips
            return-object
            multiple
            filled
            small-chips
            :rules="plantsRules"
          >
            @change="update"
            >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row v-if="selectedPlants.length">
        <v-col md="4" cols="12">
          <span class="subtitle-2">{{ $t('requestForm.assetsSection.timeframeTitle') }}</span>
          <br />
          <i class="caption">{{ $t('requestForm.assetsSection.timeframeNote') }}</i>
          <v-radio-group v-model="singleRequestTimeframe" :rules="radioRules">
            <v-radio :label="$t('requestForm.assetsSection.singleTimeframeYes')" :value="true"></v-radio>
            <v-radio :label="$t('requestForm.assetsSection.singleTimeframeNo')" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <template v-if="singleRequestTimeframe">
          <v-col md="4" cols="6">
            <v-menu
              v-model="timeMenu[0]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="accessStartDate"
                  filled
                  :label="$t('requestForm.assetsSection.validFromLabel') "
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="validFromRules"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="accessStartDate"
                :max="requestEndDate"
                :min="requestStartDate"
                @change="update"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" cols="6">
            <v-menu
              v-model="timeMenu[1]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="accessEndDate"
                  filled
                  :label="$t('requestForm.assetsSection.validToLabel') "
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="validToRules"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                v-model="accessEndDate"
                :max="calculateEndDate(accessStartDate, requestEndDate)"
                :min="accessStartDate || dayjs().format('YYYY-MM-DD')"
                @change="update"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </template>
      </v-row>
      <template v-if="!singleRequestTimeframe">
        <v-row v-for="(plant, index) in selectedPlants" :key="index">
          <v-col md="4" cols="12">
            <span class="subtitle-2">{{ plant.name }}</span>
          </v-col>
          <v-col md="4" cols="12">
            <v-menu
              v-model="menu[index]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="plant.startdate"
                  filled
                  :label="$t('requestForm.assetsSection.validFromLabel')"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="validFromRules"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="plant.startdate"
                :max="requestEndDate"
                :min="requestStartDate"
                @change="update"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" cols="12">
            <v-menu
              v-model="menu[index+1000]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="plant.enddate"
                  filled
                  :label="$t('requestForm.assetsSection.validToLabel')"
                  prepend-icon="mdi-calendar"
                  readonly
                  :rules="validToRules"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="plant.enddate"
                :max="calculateEndDate(plant.startdate,requestEndDate)"
                :min="plant.startdate || dayjs().format('YYYY-MM-DD')"
                @change="update"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
    </v-sheet>
  </v-col>
</template>
<script>
import validators from '../validators'
import { mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'AssetsSection',
  props: {
    value: { type: Array, default: () => [] }
  },
  emits: ['input'],
  data () {
    return {
      ...validators,
      dayjs,
      singleRequestTimeframe: true,
      timeMenu: [],
      menu: [],
      accessStartDate: null,
      accessEndDate: null,
      selectedPlants: [...this.value]
    }
  },
  computed: {
    ...mapState(['assets', 'requestStartDate', 'requestEndDate'])
  },
  watch: {
    selectedPlants (sp) {
      this.$emit('input', this.selectedPlants)
      sp.forEach((p) => {
        p.startdate || this.$set(p, 'startdate', null)
        p.enddate || this.$set(p, 'enddate', null)
      })
    },
    accessStartDate (sd) {
      this.syncAssetsStartEndDates()
    },
    accessEndDate (ed) {
      this.syncAssetsStartEndDates()
    },
    singleRequestTimeframe () {
      this.syncAssetsStartEndDates()
    }
  },

  methods: {
    update () {
      this.$emit('input', this.selectedPlants)
    },
    syncAssetsStartEndDates () {
      this.singleRequestTimeframe &&
        this.selectedPlants.forEach((p) => {
          p.startdate = this.accessStartDate
          p.enddate = this.accessEndDate
        })
    },
    calculateEndDate (startDate, maxDate) {
      if (startDate != null) {
        const startDateYearAfter = dayjs(startDate).add(1, 'year')
        const calcuatedEndDate = startDateYearAfter.isAfter(dayjs(maxDate))
          ? dayjs(maxDate)
          : startDateYearAfter
        return calcuatedEndDate.format('YYYY-MM-DD')
      }
    }
  }
}
</script>
