<template>
  <div>
    <v-card class="mx-auto" max-width="1000">
      <v-img class="white--text align-end" src="/img/banner.jpg">
        <v-card-title>{{ $t('validationPage.banner') }}</v-card-title>
      </v-img>

      <v-card-text class="text--primary text-center">
        <h3>{{ $t('validationPage.identifierTitle') }}</h3>
        <p>{{ $t('validationPage.instruction') }}</p>
        <v-text-field
          v-model="referenceCode"
          outlined
          dense
          class="mx-auto"
          style="width:200px;"
          :label="$t('validationPage.referenceNumberPlaceholder')"
          @keyup.enter="handleValidate()"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-btn
          :disabled="!referenceCode"
          color="primary"
          rounded
          @click="handleValidate()"
        >{{ $t('actions.validate') }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Validate',
  data: () => ({
    referenceCode: null
  }),
  computed: {
    ...mapGetters(['isValidated'])
  },
  watch: {
    isValidated () {
      this.$router.push('/information')
    }
  },
  mounted () {
    this.referenceCode = this.$route.query.id
  },
  methods: {
    handleValidate () {
      this.referenceCode && this.getAssets({ id: this.referenceCode })
    },
    ...mapActions(['getAssets', 'createMessage'])
  }
}
</script>
