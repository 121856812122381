import Vue from 'vue'
import Vuex from 'vuex'

import { requestTypes } from '../helpers'
import i18n from '../plugins/i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fetching: 0,
    messages: [],
    assets: [],
    requestStartDate: null,
    requestEndDate: null,
    referenceCode: ''
  },
  getters: {
    isFetching: state => {
      return !!state.fetching
    },
    isValidated: state => {
      return !!state.assets.length
    }
  },

  mutations: {
    MUTATE (state, payload) {
      state[payload.property] = payload.with
    },
    IS_FETCHING (state) {
      state.fetching++
    },
    FETCHING_DONE (state) {
      state.fetching--
    },
    CREATE_MESSAGE (state, message) {
      state.messages.push({ isRead: false, ...message })
    },
    RESET (state) {
      state.assets = []
      state.requestEndDate = null
      state.requestStartDate = null
    }

  },
  actions: {
    async fetchData ({ rootState, commit, dispatch }, payload) {
      commit('IS_FETCHING')
      try {
        const response = await this._vm.$api({
          method: payload.body ? 'post' : 'get',
          url: payload.url,
          data: payload.body ? payload.body : {},
          params: payload.params
        })
        if (payload.stateProperty) {
          commit('MUTATE', {
            property: payload.stateProperty,
            with: response.data
          })
        }
        return response.data
      } catch ({ error, code, response }) {
        dispatch('createMessage', { text: response && response.data ? response.data.message : error })
        throw error
      } finally {
        commit('FETCHING_DONE')
      }
    },
    async getAssets ({ commit, dispatch }, payload) {
      commit('IS_FETCHING')
      try {
        const response = await this._vm.$api({
          method: 'get',
          url: `getassets/${payload.id.startsWith('pam')
          ? payload.id.toUpperCase()
          : payload.id}`
        })

        if (response.data.assets.length === 0) {
          throw new Error()
        }

        commit('MUTATE', {
          property: 'assets',
          with: response?.data?.assets
        })

        commit('MUTATE', {
          property: 'requestStartDate',
          with: response?.data?.startdate.split(' ')[0]
        })

        commit('MUTATE', {
          property: 'requestEndDate',
          with: response?.data?.enddate.split(' ')[0]
        })
        commit('MUTATE', {
          property: 'referenceCode',
          with: payload.id
        })
        dispatch('createMessage', { text: i18n.t('messages.referenceVerified'), color: 'success' })
        return response.data.assets
      } catch ({ error, code, response }) {
        dispatch('createMessage', { text: i18n.t('messages.invalidReference'), timeout: 10000 })
        throw error
      } finally {
        commit('FETCHING_DONE')
      }
    },
    async sendRequest ({ state, commit, dispatch }, payload) {
      commit('IS_FETCHING')

      try {
        const response = await this._vm.$api({
          method: 'post',
          url: requestTypes[payload.type].path,
          data: payload
        })
        dispatch('createMessage', { text: i18n.t('messages.accessRequestSent'), color: 'success' })
        commit('RESET')
        return response.data
      } catch ({ error, code, response }) {
        dispatch('createMessage', { text: i18n.t('messages.unableToSendRequest'), timeout: 10000 })
        throw error
      } finally {
        commit('FETCHING_DONE')
      }
    },

    createMessage ({ commit, state }, message) {
      commit('CREATE_MESSAGE', message)
      return state.messages
    }
  },
  modules: { }
})
