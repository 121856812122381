import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#2272B5',
        secondary: '#FFDA00',
        accent: '#1E324F',
        error: '#F93B18',
        info: '#005C63',
        success: '#3DC07C',
        warning: '#FFC107'
      }
    }
  }
})
