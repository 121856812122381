<template>
  <v-col cols="12">
    <i class="caption">{{ $t('requestForm.educationSection.educationInformation') }}</i>
    <v-sheet>
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectedEducations"
            :items="educations"
            item-text="name"
            item-value="id"
            clearable
            deletable-chips
            return-object
            multiple
            filled
            small-chips
            @change="update"
            @keydown="addOtherEducation"
            @update:search-input="updateSearchInput"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row v-for="(education, index) in selectedEducations" :key="index">
        <v-col md="4" cols="12">
          <span class="headline">{{ education.name }}</span>
        </v-col>

        <v-col md="4" cols="12">
          <v-menu
            v-model="eduMenu[index]"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="education.validToDate"
                filled
                dense
                :label="$t('requestForm.educationSection.validToDate')"
                prepend-icon="mdi-calendar"
                readonly
                :rules="educationDateRules"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="education.validToDate" @change="update"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="4" cols="12">
          <v-btn fab x-small color="error" class="mt-2" @click="handleEducationRemove(index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
  </v-col>
</template>
<script>
import validators from '../validators'
import dayjs from 'dayjs'

export default {
  name: 'EducationSection',
  props: {
    value: { type: Array, default: () => [] }
  },
  emits: ['input'],
  data () {
    return {
      ...validators,
      dayjs,
      eduMenu: [],
      educationSearch: '',
      educations: [
        { name: 'ESA 19​', id: 'esa19' },
        { name: 'ESA Instruerad ', id: 'esai' },
        { name: 'ESA-Vattenvägar​', id: 'esav' }
        /* { name: 'Heta arbeten', id: 'heta' },
        { name: 'Förarbevis travers', id: 'trav' },
        { name: 'Förarbevis truck', id: 'truck' } */
      ],
      selectedEducations: [...this.value]
    }
  },
  watch: {
    selectedEducations (sp) {
      sp.forEach((p) => {
        p.validToDate || this.$set(p, 'validToDate', null)
      })
    },
    accessStartDate (sd) {
      this.syncAssetsStartEndDates()
    },
    accessEndDate (ed) {
      this.syncAssetsStartEndDates()
    },
    singleRequestTimeframe () {
      this.syncAssetsStartEndDates()
    }
  },

  methods: {
    handleEducationRemove (index) {
      console.log(this.selectedEducations[index])
      this.selectedEducations.splice(index, 1)
    },
    updateSearchInput (v) {
      this.educationSearch = v
    },
    update () {
      this.$emit('input', this.selectedEducations)
    },
    addOtherEducation (e) {
      if (e.keyCode === 13) {
        this.selectedEducations.push({ name: this.educationSearch, id: 'other' })
      }

      this.log += e.key
    }
  }
}
</script>
