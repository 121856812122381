var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('requestForm.assetsSection.powerPlantInformation')))]),_c('v-sheet',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.assets,"item-text":"name","item-value":"id","clearable":"","deletable-chips":"","return-object":"","multiple":"","filled":"","small-chips":"","rules":_vm.plantsRules},model:{value:(_vm.selectedPlants),callback:function ($$v) {_vm.selectedPlants=$$v},expression:"selectedPlants"}},[_vm._v(" @change=\"update\" > ")])],1)],1),(_vm.selectedPlants.length)?_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(_vm.$t('requestForm.assetsSection.timeframeTitle')))]),_c('br'),_c('i',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('requestForm.assetsSection.timeframeNote')))]),_c('v-radio-group',{attrs:{"rules":_vm.radioRules},model:{value:(_vm.singleRequestTimeframe),callback:function ($$v) {_vm.singleRequestTimeframe=$$v},expression:"singleRequestTimeframe"}},[_c('v-radio',{attrs:{"label":_vm.$t('requestForm.assetsSection.singleTimeframeYes'),"value":true}}),_c('v-radio',{attrs:{"label":_vm.$t('requestForm.assetsSection.singleTimeframeNo'),"value":false}})],1)],1),(_vm.singleRequestTimeframe)?[_c('v-col',{attrs:{"md":"4","cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"filled":"","label":_vm.$t('requestForm.assetsSection.validFromLabel'),"prepend-icon":"mdi-calendar","readonly":"","rules":_vm.validFromRules},model:{value:(_vm.accessStartDate),callback:function ($$v) {_vm.accessStartDate=$$v},expression:"accessStartDate"}},on))]}}],null,false,3595079450),model:{value:(_vm.timeMenu[0]),callback:function ($$v) {_vm.$set(_vm.timeMenu, 0, $$v)},expression:"timeMenu[0]"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":_vm.requestEndDate,"min":_vm.requestStartDate},on:{"change":_vm.update},model:{value:(_vm.accessStartDate),callback:function ($$v) {_vm.accessStartDate=$$v},expression:"accessStartDate"}})],1)],1),_c('v-col',{attrs:{"md":"4","cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"filled":"","label":_vm.$t('requestForm.assetsSection.validToLabel'),"prepend-icon":"mdi-calendar","readonly":"","rules":_vm.validToRules},model:{value:(_vm.accessEndDate),callback:function ($$v) {_vm.accessEndDate=$$v},expression:"accessEndDate"}},on))]}}],null,false,2593228853),model:{value:(_vm.timeMenu[1]),callback:function ($$v) {_vm.$set(_vm.timeMenu, 1, $$v)},expression:"timeMenu[1]"}},[_c('v-date-picker',{ref:"picker",attrs:{"max":_vm.calculateEndDate(_vm.accessStartDate, _vm.requestEndDate),"min":_vm.accessStartDate || _vm.dayjs().format('YYYY-MM-DD')},on:{"change":_vm.update},model:{value:(_vm.accessEndDate),callback:function ($$v) {_vm.accessEndDate=$$v},expression:"accessEndDate"}})],1)],1)]:_vm._e()],2):_vm._e(),(!_vm.singleRequestTimeframe)?_vm._l((_vm.selectedPlants),function(plant,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(plant.name))])]),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"filled":"","label":_vm.$t('requestForm.assetsSection.validFromLabel'),"prepend-icon":"mdi-calendar","readonly":"","rules":_vm.validFromRules},model:{value:(plant.startdate),callback:function ($$v) {_vm.$set(plant, "startdate", $$v)},expression:"plant.startdate"}},on))]}}],null,true),model:{value:(_vm.menu[index]),callback:function ($$v) {_vm.$set(_vm.menu, index, $$v)},expression:"menu[index]"}},[_c('v-date-picker',{attrs:{"max":_vm.requestEndDate,"min":_vm.requestStartDate},on:{"change":_vm.update},model:{value:(plant.startdate),callback:function ($$v) {_vm.$set(plant, "startdate", $$v)},expression:"plant.startdate"}})],1)],1),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"filled":"","label":_vm.$t('requestForm.assetsSection.validToLabel'),"prepend-icon":"mdi-calendar","readonly":"","rules":_vm.validToRules},model:{value:(plant.enddate),callback:function ($$v) {_vm.$set(plant, "enddate", $$v)},expression:"plant.enddate"}},on))]}}],null,true),model:{value:(_vm.menu[index+1000]),callback:function ($$v) {_vm.$set(_vm.menu, index+1000, $$v)},expression:"menu[index+1000]"}},[_c('v-date-picker',{attrs:{"max":_vm.calculateEndDate(plant.startdate,_vm.requestEndDate),"min":plant.startdate || _vm.dayjs().format('YYYY-MM-DD')},on:{"change":_vm.update},model:{value:(plant.enddate),callback:function ($$v) {_vm.$set(plant, "enddate", $$v)},expression:"plant.enddate"}})],1)],1)],1)}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }