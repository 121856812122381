import { ApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights = null
const defaultOptions = {
  appName: 'Vue client app',
  aiConfig: {}
}

const vuexAiPlugin = store => {
  store.subscribe((mutation) => {
    appInsights && appInsights.trackEvent({
      name: 'STATE_MUTATION',
      properties: mutation
    })
  })
  store.subscribeAction((action) => {
    appInsights && appInsights.trackEvent({
      name: 'ACTION_DISPATCH',
      properties: action
    })
  })
}

const AiPlugin = {
  /**
 * Install function passed to Vue.use()
 *
 * @param Vue
 * @param options
 */
  install (Vue, options) {
    if (!options || !options.instrumentationKey) {
      throw new Error('Plugin options including "instrumentationKey" must be specified')
    }

    options = { ...defaultOptions, ...options }

    const config = { ...defaultOptions.aiConfig, instrumentationKey: options.instrumentationKey, ...options.customConfig }

    appInsights = new ApplicationInsights({ config })
    appInsights.loadAppInsights()
    appInsights.trackPageView()

    // Watch route event if router option is defined.
    if (options.router) {
      const router = options.router

      const baseName = options.appName

      router.beforeEach((route, from, next) => {
        const name = baseName + ' / ' + route.name
        appInsights.startTrackPage(name)
        next()
      })

      router.afterEach(route => {
        const name = baseName + ' / ' + route.name
        const url = location.protocol + '//' + location.host + route.fullPath
        appInsights.stopTrackPage(name, url)
        appInsights.flush()
      })
    }

    Object.defineProperty(Vue.prototype, '$ai', {
      get: () => appInsights
    })
  }
}

export default AiPlugin
export { vuexAiPlugin }
