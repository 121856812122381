import Vue from 'vue'
import VueRouter from 'vue-router'

import { isBrowserSupported } from '../helpers'
import store from '../store'
import Validate from '../views/Validate'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Validate',
    meta: { requiresValidation: false },
    component: Validate
  },
  {
    path: '/information/',
    name: 'Information',
    meta: { requiresValidation: true },
    component: () =>
      import(/* webpackChunkName: "Information" */ '../views/Information.vue')
  },
  {
    path: '/information/security',
    name: 'Security',
    meta: { requiresValidation: true },
    component: () =>
      import(/* webpackChunkName: "SecurityVetting" */ '../views/SecurityVetting.vue')
  },
  {
    path: '/information/gdpr',
    name: 'GDPR',
    meta: { requiresValidation: true },
    component: () =>
      import(/* webpackChunkName: "GDPR" */ '../views/GDPR.vue')
  },
  {
    path: '/information/access',
    name: 'FacilityAccess',
    meta: { requiresValidation: true },
    component: () =>
      import(/* webpackChunkName: "FacilityAccess" */ '../views/FacilityAccess.vue')
  },
  {
    path: '/request-access/',
    name: 'RequestForm',
    meta: { requiresValidation: true },
    component: () =>
      import(/* webpackChunkName: "RequestForm" */ '../views/RequestForm.vue')
  },
  {
    path: '/tag-replacement',
    name: 'TagReplacementForm',
    meta: { requiresValidation: true },
    component: () =>
      import(/* webpackChunkName: "TagReplacementForm" */ '../views/TagReplacementForm')

  },
  {
    path: '/renew-access/',
    name: 'RenewAccessForm',
    meta: { requiresValidation: true },
    component: () =>
      import(/* webpackChunkName: "RenewAccessForm" */ '../views/RenewAccessForm.vue')
  },
  {
    path: '/unsupported-browser',
    name: 'UnsupportedBrowser',
    meta: { requiresValidation: false },
    component: () =>
      import(/* webpackChunkName: "UnsupportedBrowser" */ '../views/UnsupportedBrowser.vue')
  }
]

const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
  if (!isBrowserSupported()) {
    if (to.path !== '/unsupported-browser') {
      return next('/unsupported-browser')
    } else {
      return next()
    }
  }
  if (to.matched.some(record => record.meta.requiresValidation) && !store.getters.isValidated) {
    next('/')
  } else {
    next()
  }
})

export default router
