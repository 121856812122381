import i18n from '@/plugins/i18n'

function containsConsecutiveNumbers (number) {
  if (!number) return true
  const numbers = '0123456789'
  const numbersRev = '9876543210'
  return numbers.indexOf(String(number)) === -1 && numbersRev.indexOf(String(number)) === -1
}

function containsSameCharacters (text) {
  if (!text) return true
  const characters = text.split('')
  return !characters.reduce((prev, current) => prev === current ? current : false
    , characters[0])
}

export default {
  radioRules: [
    v => v !== undefined || null || i18n.t('validationErrors.radioOptionRequired')
  ],
  trainingDateRules: [
    v => !!v || i18n.t('validationErrors.trainingDateRequired')
  ],
  reasonRules: [
    v => !!v || i18n.t('validationErrors.accessReasonRequired')
  ],
  tagIdentifierSoRules: [
    v => !!v || i18n.t('validationErrors.tagSoRequired'),
    v => (v && v.length <= 20 && v.length >= 4) || i18n.t('validationErrors.tagSoLength')
  ],
  tagIdentifierMiRules: [
    v => !!v || i18n.t('validationErrors.tagMiRequired'),
    v => (v && v.length <= 20 && v.length >= 4) || i18n.t('validationErrors.tagMiLength')
  ],
  tagIdentifierRcRules: [
    v => !!v || i18n.t('validationErrors.tagRcRequired'),
    v => (v && v.length <= 20 && v.length >= 4) || i18n.t('validationErrors.tagRcLength')
  ],
  tagPinRules: [
    v => !!v || i18n.t('validationErrors.pinRequired'),
    v => ((!v) || (v && v.length <= 4 && v.length >= 4)) || i18n.t('validationErrors.pinLength'),
    v => containsConsecutiveNumbers(v) || i18n.t('validationErrors.pinConsecutive'),
    v => containsSameCharacters(v) || i18n.t('validationErrors.pinSameDigits'),
    v => /^[0-9]{4,}$/.test(v) || i18n.t('validationErrors.pinOnlyDigits')
  ],
  phoneRules: [
    v => !!v || i18n.t('validationErrors.phoneNumberRequired'),
    v => /^[0-9]{9,}$/.test(v) || i18n.t('validationErrors.phoneNumberDigitRequirement')
  ],
  passportRules: [
    v => !!v || i18n.t('validationErrors.passportNumberRequired')
  ],
  companyRules: [
    v => !!v || i18n.t('validationErrors.companyRequired'),
    v => (v && v.length >= 1 && v.length <= 50) || i18n.t('validationErrors.companyLength')
  ],
  professionRules: [
    v => !!v || i18n.t('validationErrors.professionRequired'),
    v => (v && v.length <= 50 && v.length >= 4) || i18n.t('validationErrors.professionLength')
  ],
  userRules: [
    v => ((!v) || (v && v.length <= 15 && v.length >= 3)) || i18n.t('validationErrors.userLength')
  ],
  socialSecurityNumberRules: [
    v => !!v || i18n.t('validationErrors.socialSecuritytNumberRequired'),
    v => /^([0-9]{8}-[0-9]{4})/.test(v) || i18n.t('validationErrors.socialSecuritytNumberDigitRequirement'),
    v => (v && v.length <= 13) || i18n.t('validationErrors.socialSecuritytNumberLength')
  ],
  birthDateRules: [
    v => !!v || i18n.t('validationErrors.birthDateRequired')
  ],
  validFromRules: [
    v => !!v || i18n.t('validationErrors.validFromRequired')
  ],
  validToRules: [
    v => !!v || i18n.t('validationErrors.validToRequired')
  ],
  plantsRules: [
    v => !!(v && v.length) || i18n.t('validationErrors.plantsRulesRequired')
  ],
  firstNameRules: [
    v => !!v || i18n.t('validationErrors.firstNameRequired')
  ],
  lastNameRules: [
    v => !!v || i18n.t('validationErrors.lastNameRequired')
  ],
  emailRules: [
    v => !!v || i18n.t('validationErrors.emailRequired'),
    v => /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$/i.test(v) || i18n.t('validationErrors.emailRequirement')
  ],
  educationDateRules: [
    v => !!v || i18n.t('validationErrors.educationDateRequired')
  ],
  recipientRules: [
    v => !!v || i18n.t('validationErrors.recipientRequired'),
    v => (v && v.length >= 3 && v.length <= 50) || i18n.t('validationErrors.recipientLength')
  ],

  streetRules: [
    v => !!v || i18n.t('validationErrors.streetRequired'),
    v => (v && v.length >= 3 && v.length <= 50) || i18n.t('validationErrors.streetLength')
  ],
  zipcodeRules: [
    v => !!v || i18n.t('validationErrors.zipcodeRequired'),
    v => (v && v.length >= 3 && v.length <= 50) || i18n.t('validationErrors.zipcodeLength')
  ],
  cityRules: [
    v => !!v || i18n.t('validationErrors.cityRequired'),
    v => (v && v.length >= 3 && v.length <= 50) || i18n.t('validationErrors.cityLength')
  ],
  countryRules: [
    v => !!v || i18n.t('validationErrors.countryRequired'),
    v => (v && v.length >= 3 && v.length <= 50) || i18n.t('validationErrors.countryLength')
  ]

}
